import IconMinus from '@/assets/icons/icon-minus.svg?component';
import IconPlus from '@/assets/icons/icon-plus.svg?component';
import type { AdditionalProduct, Recipe } from '@ruokaboksi/api-client';
import type { PropType, VNode } from 'vue';
import './../ProductCard.css';

export default defineComponent({
  name: 'AdditionalProductFooter',
  props: {
    editable: {
      default: false,
      type: Boolean,
    },
    product: {
      type: Object as PropType<AdditionalProduct | Recipe>,
      required: true,
    },
    selected: {
      default: false,
      type: Boolean,
    },
  },
  setup(props) {
    const store = useSelectProductsStore();

    const isAdditionalProduct = (
      product: AdditionalProduct | Recipe
    ): product is AdditionalProduct => {
      return 'maxPerDelivery' in product;
    };

    const quantity = computed(() => {
      return (
        store.selectedProductIds.filter((id) => id === props.product.id)
          .length || 0
      );
    });

    const handleIncrement = (event: Event): void => {
      event.stopImmediatePropagation();
      if (
        isAdditionalProduct(props.product) &&
        quantity.value < props.product.maxPerDelivery
      ) {
        store.selectProduct(props.product.id);
      }
    };

    const handleDecrement = (event: Event): void => {
      event.stopImmediatePropagation();
      store.unselectProduct(props.product.id);
    };

    const isOutOfStock = computed<boolean>(() => {
      if ('isOutOfStock' in props.product && props.product?.isOutOfStock) {
        return true;
      }

      return false;
    });

    const isMaxQuantityReached = computed(() => {
      if (isAdditionalProduct(props.product)) {
        return quantity.value >= (props.product.maxPerDelivery || Infinity);
      }
      return false;
    });

    return {
      handleIncrement,
      handleDecrement,
      isOutOfStock,
      quantity,
      isMaxQuantityReached,
    };
  },
  render(): VNode | null {
    if (!this.product) return null;
    return (
      <div class="product-card-footer">
        <label class="button button-label button-selected flex items-center justify-between">
          <span
            class={[
              'flex h-10 w-10 items-center justify-center',
              {
                'pointer-events-none opacity-50':
                  !this.editable || this.quantity === 0,
              },
            ]}
            role="button"
            aria-disabled={!this.editable || this.quantity === 0}
            onClick={this.handleDecrement}
            title={this.$t('product_card.remove')}
          >
            <IconMinus aria-hidden="true" class="h-6 w-6" />
          </span>
          <span class="mx-4 text-lg">{this.quantity}</span>{' '}
          <span
            class={[
              'flex h-10 w-10 items-center justify-center',
              {
                'pointer-events-none opacity-50':
                  !this.editable || this.isMaxQuantityReached,
              },
            ]}
            role="button"
            aria-disabled={!this.editable || this.isMaxQuantityReached}
            onClick={this.handleIncrement}
            title={this.$t('product_card.add')}
          >
            <IconPlus aria-hidden="true" class="h-6 w-6" />
          </span>
        </label>
      </div>
    );
  },
});
