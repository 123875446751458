import IconLock from '@/assets/icons/icon-lock.svg?component';
import WeekDiscountBadge from '@/components/WeekDiscountBadge';
import { getExceptionDeliveryDate } from '@/utils/exceptionDeliveryDates';
import type { Language } from '@ruokaboksi/api-client';
import { formatDayOfWeek2 } from '@ruokaboksi/utils';
import { format, getISODay } from 'date-fns';
import type { DeliveryWeekWithFormattedDays } from 'models';

export default defineComponent({
  name: 'WeekSelectorButton',
  props: {
    deliveryWeek: {
      // biome-ignore lint/suspicious/noEmptyBlockStatements: <explanation>
      default: () => {},
      type: Object as PropType<DeliveryWeekWithFormattedDays>,
    },
    handleChange: {
      // biome-ignore lint/suspicious/noEmptyBlockStatements: <explanation>
      default: () => {},
      type: Function as PropType<(payload: Event) => void>,
    },
    selected: {
      default: false,
      type: Boolean,
    },
  },

  setup() {
    const { locale } = useI18n();
    return {
      locale,
    };
  },
  render(): VNode {
    const deliveryDate =
      getExceptionDeliveryDate(new Date(this.deliveryWeek.deliveryDate)) ??
      new Date(this.deliveryWeek.deliveryDate);

    const paused = this.$props.deliveryWeek.paused;
    const selected = this.$props.selected;
    return (
      <label
        class={{
          'border-1 max-w-18 lg:text-md relative flex h-20 w-full flex-col items-center justify-center pb-4 pt-4 text-center text-sm first:rounded-l-sm': true,
          'border-rb-green bg-rb-light-green text-rb-green': !paused,
          'border-rb-bright-red text-rb-red diagonal-line': paused,
          '!border-6 bg-light-green text-rb-green z-1 scale-110 rounded-sm':
            selected,
          selected,
          paused,
        }}
      >
        <input
          class="absolute h-0 w-0 opacity-0"
          name="week-selector"
          onChange={this.handleChange}
          type="radio"
          value={this.deliveryWeek.weekString}
        />
        <div
          class={`flex w-[70%] flex-col ${
            paused && 'bg-rb-light-red'
          } items-center`}
        >
          {this.deliveryWeek.locked && <IconLock class={'h-3'} />}
          <span class={'text-s font-light'}>
            {formatDayOfWeek2(getISODay(deliveryDate), this.locale as Language)}
          </span>
          <span class="xs:leading-6 flex flex-col font-bold md:leading-7">
            <time datetime={deliveryDate.toISOString()}>
              {format(deliveryDate, 'd.M.')}
            </time>
          </span>
        </div>
        {this.deliveryWeek.editable && (
          <WeekDiscountBadge deliveryWeek={this.deliveryWeek} size="mini" />
        )}
      </label>
    );
  },
});
