import { storeToRefs } from 'pinia';
import type { VNode } from 'vue';
import WeekSelectorButton from '../../components/WeekSelectorButton';
import type { DeliveryWeekWithFormattedDays } from '../../models';
import './WeekSelector.css';
import MoreWeeksButton from '../../components/MoreWeeksButton';

export default defineNuxtComponent({
  name: 'WeekSelector',
  props: {
    title: {
      default: '',
      type: String,
    },
  },
  setup() {
    const {
      deliveryWeeks,
      isFetchedDeliveryWeeks,
      isLoadingDeliveryWeeks,
      validatedQueryWeekString,
    } = useDeliveryWeeks();

    const { invalidateDeliveryWeek } = useDeliveriesApi();

    const router = useRouter();
    const { currentCustomer } = useCurrentCustomer();
    const selectProductsStore = useSelectProductsStore();
    const { hasProductSelectionChanged } = storeToRefs(selectProductsStore);
    const { t } = useI18n();

    const selectorWeeks = computed<DeliveryWeekWithFormattedDays[]>(() => {
      return deliveryWeeks.value.slice(0, SELECTOR_WEEKS_AMOUNT);
    });

    /** Second week from available delivery weeks. The two first ones are in the past. */
    const initialWeek = computed<DeliveryWeekWithFormattedDays | null>(() => {
      if (selectorWeeks.value.length < 2) return null;

      const firstFutureNonLockedAndPausedWeek = selectorWeeks.value.find(
        (week) => !(week.locked && week.paused) && week.editable
      );

      return firstFutureNonLockedAndPausedWeek ?? selectorWeeks.value[2];
    });

    const selectedWeekNumber = computed<string>(
      () =>
        selectorWeeks.value.find(
          (d) => d.weekString === selectedWeekString.value
        )?.weekNumberString || ''
    );

    const selectedWeekString = computed<string>(
      () => validatedQueryWeekString.value || ''
    );

    const selectWeek = (weekString: string): void => {
      const validatedQueryWeek = selectorWeeks.value
        .map(({ weekString }) => weekString)
        .includes(weekString)
        ? weekString
        : '';
      router.push({
        query: {
          // If the deliveryWeek from query is not valid, we just use the default
          selectedWeek: validatedQueryWeek || initialWeek.value?.weekString,
        },
      });
    };

    const initSelectedWeek = (): void => {
      if (!validatedQueryWeekString.value && initialWeek.value) {
        selectWeek(initialWeek.value.weekString);
        invalidateDeliveryWeek(initialWeek.value.paymentDate);
      }
    };

    const handleChange = (event: Event): void => {
      const input = event.target as HTMLInputElement;
      if (
        !hasProductSelectionChanged.value ||
        (hasProductSelectionChanged.value &&
          window.confirm(t('week_selector.confirm')))
      ) {
        selectWeek(input.value);
      } else {
        event.preventDefault();
      }
    };

    watch(isFetchedDeliveryWeeks, initSelectedWeek);
    onMounted(initSelectedWeek);

    return {
      selectorWeeks,
      handleChange,
      selectedWeekNumber,
      currentCustomer,
      selectedWeekString,
      isLoadingDeliveryWeeks,
    };
  },
  render(): VNode | null {
    if (this.isLoadingDeliveryWeeks) return null;

    return (
      // Negative margin is used to expand the weekselector over the .content container
      <form class="week-selector -mx-3 flex justify-center sm:mx-0 lg:pb-4">
        <fieldset class="w-full">
          {this.title ? (
            <legend class="main-heading mx-auto text-center max-lg:hidden">
              {this.title}
            </legend>
          ) : null}
          <div class="mx-auto mb-4 inline-flex w-full justify-center lg:mt-6">
            {this.selectorWeeks.length ? (
              <>
                {this.selectorWeeks.map((deliveryWeek) => (
                  <WeekSelectorButton
                    deliveryWeek={deliveryWeek}
                    handleChange={this.handleChange}
                    selected={
                      deliveryWeek.weekNumberString ===
                      this.selectedWeekNumber.toString()
                    }
                  />
                ))}
                <MoreWeeksButton />
              </>
            ) : null}
          </div>
        </fieldset>
      </form>
    );
  },
});
